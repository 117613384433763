<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-0">Créer une URL de campagne aléatoire</p>
    </div>
    <v-card>
      <v-card-text>
        <v-row class="ma-5">
          <v-col cols="12">
            <p>
              Avoir une URL par campagne qui redirige au hasard sur un des N
              derniers articles d'un flux RSS en paramètre
            </p>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="3"></v-col>
          <v-col cols="5">
            <v-text-field
              v-model="rss"
              label="Flux RSS valide"
              :class="{ 'red-border': redBorders.rss }"
              outlined
              dense
              hint="https://www.closermag.fr/feed"
              :loading="validateRssIsLoading"
            />
          </v-col>
          <v-col cols="1" class="text-left">
            <v-btn
              v-if="!rssIsValidated || rss !== lastValidatedRss"
              :loading="validateRssIsLoading"
              color="secondary"
              @click="validateRss"
              class="ml-4"
              >Vérifier</v-btn
            >
            <v-icon v-else color="success" class="ml-4 mt-2"
              >{{ icons.mdiCheckCircle }} </v-icon
            ><!--mdi-check-circle-->
          </v-col>
          <v-col cols="3" class="text-left"> </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="3"></v-col>
          <v-col cols="6">
            <v-text-field
              v-model="utm_source"
              label="utm_source"
              :class="{ 'red-border': redBorders.utm_source }"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
        <v-row class="">
          <v-col cols="3"></v-col>
          <v-col cols="6">
            <v-text-field
              v-model="utm_medium"
              label="utm_medium"
              :class="{ 'red-border': redBorders.utm_medium }"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
        <v-row class="">
          <v-col cols="3"></v-col>
          <v-col cols="6">
            <v-text-field
              v-model="utm_campaign"
              label="utm_campaign"
              :class="{ 'red-border': redBorders.utm_campaign }"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
        <v-row class="mx-2">
          <v-col cols="3"></v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="6">
                <div style="width: 150px">
                  <v-text-field
                    v-model="limit"
                    label="Limite"
                    :class="{ 'red-border': redBorders.limit }"
                    outlined
                    inline
                    dense
                  />
                </div>
              </v-col>
              <v-col cols="6" class="text-right">
                Afficher le debug &nbsp;
                <v-btn-toggle v-model="toggleDebugMode" mandatory>
                  <v-btn> Non </v-btn>
                  <v-btn> Oui </v-btn>
                  <v-btn> Dev </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" class="text-center">
            <v-btn color="primary" @click="generateUrl" class="ml-4"
              >Créer l'URL</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="ma-4 mt-6">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div v-show="urlResult != ''" class="mt-12">
              <p>URL résultat :</p>
              <p class="text-center">
                <a :href="urlResult" target="_blank">{{ urlResult }}</a>
              </p>
              <p class="text-center">
                <v-btn color="primary" @click="copyToClipboard" class="ml-4"
                  >Copier</v-btn
                >
              </p>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  onMounted,
  getCurrentInstance,
} from "@vue/composition-api";
import useAxios from "@/hooks/useAxios";
import store from "@/store";
import { NOTIF_DISPLAY_DURATION } from "@/utils/constants";
import { mdiCheckCircle } from "@mdi/js";
import { saveRmraEvent, saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "CampaignRedirect",
  setup() {
    const instance = getCurrentInstance();
    const route = instance.proxy.$route;

    const toggleDebugMode = ref(0);
    const rss = ref("https://www.closermag.fr/feed");
    const lastValidatedRss = ref("");
    const validateRssIsLoading = ref(false);
    const rssIsValidated = ref(false);
    const utm_source = ref("");
    const utm_medium = ref("");
    const utm_campaign = ref("");
    const redBorders = reactive({
      rss: false,
      utm_source: false,
      utm_medium: false,
      utm_campaign: false,
      limit: false,
    });
    const limit = ref("");
    const debug = ref("");
    const urlResult = ref("");

    const { axiosGet } = useAxios();

    onMounted(async () => {
      saveRmraView(route);
    });

    const raiseError = async (message) => {
      await store.dispatch("app/setSnackBar", {
        show: true,
        text: message,
        color: "error",
        timeout: NOTIF_DISPLAY_DURATION,
      });
    };
    const raiseSuccess = async (message) => {
      await store.dispatch("app/setSnackBar", {
        show: true,
        text: message,
        color: "success",
        timeout: NOTIF_DISPLAY_DURATION,
      });
    };

    const validateRss = async () => {
      rss.value = rss.value.trim();
      if (!rss.value) {
        raiseError(`Veuillez entrer l'url d'un flux RSS.`);
      }
      validateRssIsLoading.value = true;
      try {
        const response = await axiosGet(
          "/arbitrage_campaign/check-rss-feed-validity",
          {
            rss: rss.value,
          }
        );
        if (response.data.is_valid) {
          rssIsValidated.value = true;
          redBorders.rss = false;
          lastValidatedRss.value = rss.value;
        } else {
          raiseError(`Le flux RSS n'est pas valide.`);
          redBorders.rss = true;
        }
      } catch (error) {
        console.error("Error validating RSS feed:", error);
        raiseError(`Une erreur est survenue. ${error}`);
        rssIsValidated.value = false;
        redBorders.rss = true;
      }
      validateRssIsLoading.value = false;
    };

    const generateUrl = async () => {
      await validateRss();
      if (!rssIsValidated.value) {
        return;
      }

      utm_source.value = utm_source.value.trim();
      utm_medium.value = utm_medium.value.trim();
      utm_campaign.value = utm_campaign.value.trim();
      limit.value = limit.value.trim();

      redBorders.utm_source = utm_source.value == "";
      redBorders.utm_medium = utm_medium.value == "";
      redBorders.utm_campaign = utm_campaign.value == "";

      if (
        limit.value != "" &&
        !(parseInt(limit.value) >= 0 && parseInt(limit.value) <= 100)
      ) {
        redBorders.limit = true;
        raiseError(`La limite doit être un entier entre 0 et 100`);
        return;
      } else {
        redBorders.limit = false;
      }

      const params = new URLSearchParams({ rss: rss.value });
      if (utm_source.value) params.append("utm_source", utm_source.value);
      if (utm_medium.value) params.append("utm_medium", utm_medium.value);
      if (utm_campaign.value) params.append("utm_campaign", utm_campaign.value);
      if (limit.value) params.append("limit", limit.value);

      const debugs = { 1: "print-and-exit", 2: "true" };
      if (toggleDebugMode.value && parseInt(toggleDebugMode.value) > 0) {
        params.append("debug", debugs[parseInt(toggleDebugMode.value)]);
      }

      const url = `https://campaign-redirect.rmr.reworldmediafactory.com?${params.toString()}`;

      urlResult.value = url;
      saveRmraEvent("campaign-redirect-create-link", "use");
    };

    const copyToClipboard = async () => {
      if (!urlResult.value) {
        alert("Aucune URL à copier.");
        return;
      }
      navigator.clipboard.writeText(urlResult.value).then(
        async () => {
          raiseSuccess(`URL copiée dans le presse-papier.`);
          saveRmraEvent("campaign-redirect-copy-link", "use");
        },
        (err) => console.error("Erreur lors de la copie :", err)
      );
    };

    watch(
      () => rss.value,
      (newValue, oldValue) => {
        if (newValue !== oldValue && newValue !== lastValidatedRss.value) {
          rssIsValidated.value = false;
        }
      }
    );

    watch(
      [rss, utm_source, utm_medium, utm_campaign, limit, toggleDebugMode],
      () => {
        urlResult.value = "";
      }
    );

    return {
      rss,
      utm_source,
      utm_medium,
      utm_campaign,
      redBorders,
      limit,
      debug,
      urlResult,
      validateRss,
      validateRssIsLoading,
      rssIsValidated,
      lastValidatedRss,
      generateUrl,
      copyToClipboard,
      toggleDebugMode,
      icons: {
        mdiCheckCircle,
      },
    };
  },
};
</script>

<style scoped>
.col {
  margin: 0;
  padding: 0;
}
.red-border::v-deep fieldset {
  border: 1px solid red !important;
}
</style>
